import React from "react";
import { graphql, Link } from "gatsby";
import { gql } from "@apollo/client";
import { Helmet } from "react-helmet";
import striptags from "striptags";

import { unwrap } from "../components/WagtailWrapper";
import { ProseRichText } from "../components/RichText";
import { Label } from "../components/Labels";
import {
  Grid,
  Content,
  TightContent,
  Section,
  Frame,
} from "../components/Layouts";
import { MinimalHeader } from "../components/MinimalHeader";
import { PersonImage } from "../components/Images";
import {
  ExperimentCard,
  PresentationCard,
  PublicationCard,
  HistologyCard,
  InstitutionCardFragment,
  ExperimentCardFragment,
  PresentationCardFragment,
  PublicationCardFragment,
  HistologyCardFragment,
  InstitutionInfo,
} from "../components/Card";
import { Title, Prose } from "../components/Prose";
import { WagtailEmbed } from "../components/Wagtail";
import { Breadcrumbs } from "../components/SEOTags";
import { colorList } from "../components/Colors";
import { Facet } from "../components/Facets";

export const Component = ({ page }) => (
  <>
    <Helmet>
      <script type="application/ld+json">
        {`{
            "@context": "http://schema.org",
            "@type": "Person",
            "affiliation": "${page?.affiliates}",
            "email": "${page?.email}",
            "jobTitle": "${page?.titles}",
            "description": "${striptags(page?.short)}",
            "mainEntityOfPage": "https://cbtn.org/research-partners/${
              page?.slug
            }",
            "name": "${page?.title}"
        }`}
      </script>
    </Helmet>
    <Frame
      page={page}
      url={`research-partners/${page?.slug}`}
      title={`${page?.title} ${page?.credentials
        .map((c) => c.name)
        .join(", ")}`}
    >
      <Section>
        <Content>
          <header>
            <Breadcrumbs
              url={"research-partners"}
              list={"Research Collaborators"}
              title={page?.title}
            />
            <div className="flex flex-col sm:flex-row items-baseline sm:space-x-4">
              <Title>{page?.title}</Title>
              <div className="text-3xl">
                {page?.credentials.map((c) => c.name).join(", ")}
              </div>
            </div>
            <div className="flex flex-wrap">
              {page?.email && (
                <a
                  href={`mailto:${page?.email}`}
                  className="mt-3 mr-3 flex items-center text-sm leading-5 sm:mr-6 text-blue-600 hover:underline visited:text-purple-600"
                >
                  <svg
                    className="flex-shrink-0 mr-1.5 h-5 w-5"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
                  </svg>
                  Send Email
                </a>
              )}
              {page?.website && (
                <a
                  href={page?.website}
                  target="blank"
                  className="mt-3 mr-3 flex items-center text-sm leading-5 sm:mr-6 text-blue-600 hover:underline visited:text-purple-600"
                >
                  <svg
                    className="flex-shrink-0 mr-1.5 h-5 w-5"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"></path>
                  </svg>
                  Visit Website
                </a>
              )}
            </div>
            <div>
              {page?.leadershipTypes?.map((h) => (
                <Link
                  key={h?.name}
                  to={`/research-partners?filters=${h?.name}`}
                >
                  <Facet color={colorList[(h?.name).length % colorList.length]}>
                    {h?.name}
                  </Facet>
                </Link>
              ))}
              <Label>{page?.location}</Label>
              <Label>{page?.memberInstitution?.title}</Label>
            </div>
          </header>
        </Content>
      </Section>
      <div className="bg-gray-100">
        <div className="relative">
          <div className="absolute inset-0 h-1/2 bg-white"></div>
          <div className="relative mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-center">
              <div className="flex-shrink-0">
                <div className="relative w-64 h-64">
                  <div className="absolute bg-white h-full w-full object-cover rounded-full">
                    <PersonImage image={page?.image} height={512} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Section theme="dark" shadow={false}>
        {page?.video && <WagtailEmbed block={{ value: page?.video }} />}
        {(page?.titles || page?.affiliates || page?.description) && (
          <TightContent>
            <MinimalHeader title="About" />
            <Prose>
              {page?.titles && <h3>{page?.titles}</h3>}
              {page?.affiliates && <h3>{page?.affiliates}</h3>}
              {page?.description && <ProseRichText html={page?.description} />}
              {page?.expertise?.length > 0 && (
                <>
                  <h3>Expertise</h3>
                  <Prose>
                    <p> {page?.expertise.map((c) => c.name).join(", ")} </p>
                  </Prose>
                </>
              )}
            </Prose>
            <div className="flex justify-center mt-6">
              <InstitutionInfo institution={page?.memberInstitution} />
            </div>
          </TightContent>
        )}
      </Section>
      <Section>
        {page?.scientificProjects?.length > 0 && (
          <Content>
            <MinimalHeader subTitle="scientific" title="Projects" />
            <Grid>
              {page?.scientificProjects.map((scientificProject) => (
                <ExperimentCard
                  key={scientificProject.slug}
                  item={scientificProject}
                />
              ))}
            </Grid>
          </Content>
        )}
        {page?.histologies?.length > 0 && (
          <Content>
            <MinimalHeader subTitle="research" title="Interests" />
            <Grid>
              {page?.histologies.map((histology) => (
                <HistologyCard key={histology.slug} item={histology} />
              ))}
            </Grid>
          </Content>
        )}
        {page?.presentations?.length > 0 && (
          <Content>
            <MinimalHeader subTitle="related" title="Presentations" />
            <Grid>
              {page?.presentations.slice(0, 5).map((presentation) => (
                <PresentationCard key={presentation.slug} item={presentation} />
              ))}
            </Grid>
          </Content>
        )}
        {page?.publications?.length > 0 && (
          <Content>
            <MinimalHeader subTitle="related" title="Publications" />
            <Grid>
              {page?.publications.slice(0, 5).map((publication) => (
                <PublicationCard key={publication.slug} item={publication} />
              ))}
            </Grid>
          </Content>
        )}
      </Section>
    </Frame>
  </>
);

export const previewQuery = gql`
  query previewPersonPage($contentType: String!, $token: String!) {
    page(contentType: $contentType, token: $token) {
      id
      slug
      title
      seoDescription
      seoTitle
      ... on PersonPage {
        email
        titles
        affiliates
        location
        video
        credentials {
          name
        }
        leadershipTypes {
          name
        }
        description
        short
        website
        image {
          src
          title
        }
        expertise {
          name
        }
        memberInstitution {
          ...InstitutionCardFragment
        }
        histologies {
          ...HistologyCardFragment
        }
        presentations {
          ...PresentationCardFragment
        }
        scientificProjects {
          ...ExperimentCardFragment
        }
        publications {
          ...PublicationCardFragment
        }
      }
    }
  }
  ${InstitutionCardFragment}
  ${HistologyCardFragment}
  ${PresentationCardFragment}
  ${ExperimentCardFragment}
  ${PublicationCardFragment}
`;

export const query = graphql`
  query PersonPage($id: Int) {
    wagtail {
      page(id: $id) {
        id
        slug
        title
        seoDescription
        seoTitle
        ... on Wagtail_PersonPage {
          email
          titles
          affiliates
          location
          credentials {
            name
          }
          video
          leadershipTypes {
            name
          }
          expertise {
            name
          }
          short
          description
          website
          image {
            src
            title
          }
          memberInstitution {
            ...Wagtail_InstitutionCardFragment
          }
          histologies {
            ...Wagtail_HistologyCardFragment
          }
          presentations {
            ...Wagtail_PresentationCardFragment
          }
          scientificProjects {
            ...Wagtail_ExperimentCardFragment
          }
          publications {
            ...Wagtail_PublicationCardFragment
          }
        }
      }
    }
  }
`;

export default unwrap(Component);
